@import './variables';

.photo {
  background: {
    color: $color-gray-light;
    repeat: no-repeat;
    position: center bottom;
  }
  height: 100%;
  width: 100%;

  &_woman {
    background-image: url('../images/female.svg');
  }

  &_man {
    background-image: url('../images/male.svg');
  }
}

.team {
  &__frame {
    height: 100px;
    height: 150px;
    margin: auto auto $margin-bottom auto;
    width: 80%;
  }

  &__member {
    margin-bottom: $margin-bottom;
  }
}

@include media-breakpoint-up(lg) {
  .team__frame {
    height: 200px;
  }
}

@include media-breakpoint-up(xl) {
  .team__frame {
    height: 250px;
  }
}
