@import '~bootstrap';
@import './variables';

a {
  color: $color-blue-dark;
}

h3 {
  color: $color-primary;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

// Components
@import './card';
@import './frame';
@import './footer';
@import './Header';
@import './jumbotron';

// Pages
@import './company';
@import './contacts';
@import './services';
@import './team';
