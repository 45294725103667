.card {
  @include border();
  box-shadow: $box-shadow;
  margin-bottom: $margin-bottom;

  &--warning {
    background-color: $color-yellow-light;
    border-color: $color-warning;
  }

  &__title {
    background-color: $color-primary;
    color: $color-white;
    font-weight: bold;
    padding: $default-padding;

    &--warning {
      background-color: $color-warning;
    }
  }

  &__content {
    padding: $default-padding - $default-border;
    color: $color-gray-dark;
  }

  &__link:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(md) {
  .card__content {
    min-height: 284px;
  }
}

@include media-breakpoint-up(lg) {
  .card__content {
    min-height: 212px;
  }
}

@include media-breakpoint-up(xl) {
  .card__content {
    min-height: 169px;
  }
}
