@import './variables';

.contacts {
  margin-bottom: $margin-bottom;

  &_map {
    height: 400px;
    box-shadow: $box-shadow;
    border: {
      bottom-width: 5px;
      radius: $border-radius;
      color: $color-primary;
    }
  }
}
