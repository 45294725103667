@import './variables';

.company {
  margin-bottom: $margin-bottom;

  &__side_bar {
    background: {
      color: $color-gray-light;
      image: url('../images/company.jpg');
      repeat: no-repeat;
      position: center;
    }
    height: 445px;
  }
}
