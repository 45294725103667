@import './variables.scss';

.header {
  border-bottom: 1px solid $color-gray-light;
  margin-bottom: $margin-bottom;

  .navbar-nav {
    font-weight: bold;
    
    .nav-link {
      color: lighten($color-blue-dark, 10%);

      &:hover,
      &:focus, 
      &.active {
        color: $color-blue-dark;
      }
    }
  }
}
