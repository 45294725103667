@font-face {
  font-family: 'Laca Medium';
  src: url('LacaMedium.woff');
}

@font-face {
  font-family: 'Caviar Dreams';
  src: url('CaviarDreams.ttf');
}

// Colors
$color-white: #ffffff;
$color-gray: #919191;
$color-gray-dark: #333;

$color-gray-light: #f3f6f7;
$color-blue-lightest: #abd9e0;
$color-blue-light: #87c7d1;
$color-blue-dark: #495971;
$color-yellow: #CF8432;
$color-yellow-light: #fcf8e3;

$color-primary: $color-blue-dark;
$color-secondary: $color-blue-light;
$color-background: $color-gray-light;
$color-warning: $color-yellow;
$color-warning-background: $color-yellow-light;

$width-xs-max: 767px;
$width-sm-min: $width-xs-max + 1px;
$width-sm-max: 991px;
$width-md-min: $width-sm-max + 1px;
$width-md-max: 1199px;
$width-lg-min: $width-md-max + 1px;

$border-radius: 6px;

@mixin border() {
  border: solid $color-primary 5px;
  border-radius: $border-radius;
}
$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$margin-bottom: 30px;

$default-padding: 15px;
$default-border: 5px;
$footer-top-margin: 5px;
