@import './variables';

.jumbotron {
  @include border();
  box-shadow: $box-shadow;
  position: relative;
  background: {
    color: $color-gray;
    position-x: -160px;
    position-y: -270px;
  }

  &__text {
    fill: $color-white;
    text: {
      shadow: 2px 2px $color-primary;
      align: center;
    }
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}

@media (min-width: $width-sm-min) {
  .jumbotron {
    background-image: url('../images/slide1.jpg');
    height: 400px;
  }
}

@media (min-width: $width-md-min) {
  .jumbotron {
    background-position-x: 0;
  }
}
